$('.delete_course').on('click', function(e){
    let id = $(e.currentTarget).attr('data-id');

    Swal.fire({
        title: 'Weet je het zeker dat je dit wilt verwijderen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E1E1E1',
        cancelButtonColor: '#FF2B2B',
        cancelButtonText: 'Verwijderen',
        confirmButtonText: 'Annuleren'
    }).then((result) => {
        if (result.isConfirmed) {
            $.ajax({
               
               url: APP_URL + "/course-edit/" + id + '/delete',
               type: "GET",
           })
            Swal.fire(
                'Verwijderd!',
                'Succesvol verwijderd.',
                'success'
            ).then(function() {
                location.href = APP_URL + "/cursus-overview";
            });
        }
    })
})

$('.delete_media').on('click', function(e){
    let id = $(e.currentTarget).attr('data-id');

    Swal.fire({
        title: 'Weet je het zeker?',
        text: "Deze actie kan niet ongedaan worden!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#D0A84F',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Annuleer',
        confirmButtonText: 'Verwijder dit mediabestand'
    }).then((result) => {
        if (result.isConfirmed) {
            $.ajax({
               
               url: "/media/" + id + '/destroy',
               type: "GET",
           })
            Swal.fire(
                'Verwijderd!',
                'Succesvol verwijderd.',
                'success'
            ).then(function() {
                location.href = "/media/";
            });
        }
    })
})

